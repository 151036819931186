import DateCreationEditionMixin from "../../mixins/DateCreationEditionMixin.js";
import Model, {type} from "../../abstract/Model.js"

/**
 * @class
 * @extends AbstractModel
 * @augments DateCreationEditionMixin
 */
export default class CmsAnalyticsPage extends DateCreationEditionMixin(Model) {
    static collection = `cms_analytics_pages`;

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true,
        index: 1
    }

    websiteId = {
        [type]: String,
        required: true,
        index: 1
    }

    sessionId = {
        [type]: String,
        required: true,
        index: 1
    }

    pageId = {
        [type]: String,
        required: true,
        index: 1
    }

    type = {
        [type]: String,
        default: `view`,
        required: true
    }

    permalink = {
        [type]: String
    }

    constructor() {
        super(...arguments);
    }
}
