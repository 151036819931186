<template>
    <component :is="this.dynamicComponent"/>
</template>

<script>
import SessionTracker from "@/helpers/SessionTracker.js";
import CmsAnalyticsPage from "../../common/db/models/cms/CmsAnalyticsPage.js";
import NetworkV2 from "../../vue-components/helpers/NetworkV2.js";

// cms-page-selector #hot-reload-bugfix
export default {
    name: `cms-page-selector`,
    metaInfo() {
        if (this.cmsPage) {
            return this.cmsPage.getMeta();
        } else {
            return null;
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        dynamicComponent() {
            if (!this.cmsPage) {
                return () => import(`@/views/default-error-page.vue`);
            }

            switch (this.cmsPage.type) {
                case `map`:
                    return () => import(`@/views/cms-page-map.vue`);
            }

            if (this.cmsPage.blocks) {
                return () => import(`@/views/cms-page-blocks.vue`);
            }

            return () => import(`@/views/cms-page-html.vue`);
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if (this.loading) {
            return;
        }
        this.loading = true;
        try {
            await this.$store.dispatch(`loadPage`, to);

            next();

            if (this.cmsPage && SessionTracker.session) {
                const analyticsPage = this.getAnalyticsPage();

                await NetworkV2.post(`/api/public/analytics/pages/add`, analyticsPage.toBody());
            }
        } catch(e) {
            console.error(`couldn't load page`);
        }
        this.loading = false;
    },
    created() {
        if (this.dealerid) {
            let sessionData = {
                dealerid: this.dealerid,
                websiteId: this.cmsWebsite.id,
                utms: {}
            };

            if (this.$store.state.sessionData.source) {
                sessionData.source = this.$store.state.sessionData.source;
            }
            if (this.$store.state.sessionData.medium) {
                sessionData.medium = this.$store.state.sessionData.medium;
            }

            for (let key in this.$store.state.sessionData.utms) {
                if (this.$store.state.sessionData.utms[key] !== null) {
                    sessionData.utms[key] = this.$store.state.sessionData.utms[key];
                }
            }

            const analyticsPage = this.getAnalyticsPage();
            // analyticsPage might be empty if the page couldn't be loaded.
            if (analyticsPage) {
                sessionData.page = analyticsPage.toBody();

                SessionTracker.init(sessionData);
            }
        }
    },
    methods: {
        getAnalyticsPage() {
            if (!this.cmsPage)
                return;

            return new CmsAnalyticsPage({
                sessionId: SessionTracker.session?.id,
                websiteId: this.cmsWebsite.id,
                dealerid: this.dealerid,
                pageId: this.cmsPage.id,
                permalink: this.$route.fullPath
            });
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
